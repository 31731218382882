<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Loading
      :active.sync="showLoader"
      :isFullPage="false"
      :zIndex="9000"
    ></Loading>
    <Dialog
      :visible.sync="this.show"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="`Classificação de Negócios `"
      :modal="true"
      :closable="false"
    >
      <DataTable
        :value="classificationList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="showLoader"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="classification.nameAlias"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
      >
        <template #header>
          <div class="p-col-6">
            <div class="p-inputgroup">
              <InputText
                placeholder="Pesquisar"
                v-model="searchValue"
                id="searchInput"
                class="p-col-6"
                @keyup.enter="search"
              />
              <Button
                icon="pi pi-search"
                class="p-button-warning"
                @click="search"
              />
            </div>
            <small class="p-field p-col-12 p-md-12 p-error" v-if="errorMsg">
              {{ errorMsg }}
            </small>
          </div>
        </template>
        <Column
          v-if="!errorMsg"
          sortable
          field="marketing_name"
          filterField="marketing_name"
          header="Classificação de Negócio"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          v-if="!errorMsg"
          sortable
          field="seller.username"
          filterField="seller.username"
          header="Vendedor"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>

        <Column v-if="!errorMsg">
          <template #body="slotProps">
            <Button
              icon="pi pi-check"
              class="p-button-sm p-button-rounded p-button-outlined"
              v-tooltip="'Associar a esta classificação'"
              @click="selectClassification(slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import businessService from "../../services/business.service";
export default {
  name: "AddBusinessToClassification",
  components: {
    Loading,
  },
  props: ["show"],
  watch: {
    async show() {
      if (this.show === true) {
        this.showLoader = false;
      } else {
        this.reset();
      }
    },
  },
  data() {
    return {
      showLoader: false,
      classificationList: null,
      searchValue: null,
      errorMsg: null,
      filters: {
        marketing_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "seller.username": { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async created() {},
  methods: {
    reset() {
      this.classificationList = null;
      this.searchValue = null;
      this.errorMsg = null;
      return (this.showLoader = false);
    },
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    selectClassification(id) {
      this.$emit("selectedClassification", id);
      this.reset();
    },
    async search() {
      if (
        this.searchValue == null ||
        this.searchValue == "" ||
        this.searchValue.trim() == ""
      ) {
        return (this.errorMsg = "Introduza um termo válido");
      }
      this.errorMsg = null;
      this.showLoader = true;
      this.classificationList = await businessService.search(this.searchValue);
      this.showLoader = false;
    },
  },
};
</script>
