<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="this.show"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="`Classificação de Negócios `"
      :modal="true"
      :closable="false"
    >
      <form
        name="classification-business-form"
        class="p-col-12"
        @submit.prevent="saveClassification"
        style="min-height: 40vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-8 p-mt-2">
            <span class="p-float-label">
              <InputText
                v-model="classification.marketing_name"
                :autoResize="true"
                v-bind:class="['form-control']"
                :id="'marketingName'"
                :name="'marketingName'"
                :disabled="!can('editBusinessNameToClassification')"
                v-validate="'required'"
              />
              <label for="marketingName">Nome para o Marketing</label>
            </span>
            <small
              v-if="errors.has('marketingName')"
              class="p-error"
              role="alert"
            >
              Nome para o Marketing é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="caseStudy">Caso de Estudo</label>
            </span>
            <InputSwitch
              v-model="classification.case_study"
              id="caseStudy"
              inputId="caseStudy"
              :name="'caseStudy'"
              style="'top: 3px;"
              :disabled="!can('editBusinessNameToClassification')"
            />
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="caseStudy">Edição Finalizada</label>
            </span>
            <InputSwitch
              v-model="classification.closed"
              id="closed"
              inputId="closed"
              :name="'closed'"
              style="'top: 3px;"
            />
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="businessSeller"
                :options="sellerList"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="sellerId"
                v-bind:class="['form-control']"
                :disabled="!can('editBusinessNameToClassification')"
                v-validate="'required'"
              >
                <template #option="slotProps">
                  {{ slotProps.option.name }}
                </template>
              </Dropdown>
              <label for="businessSeller">Vendedor</label>
            </span>
            <small
              v-if="errors.has('businessSeller')"
              class="p-error"
              role="alert"
            >
              Vendedor é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Textarea
                v-model="classification.description_project"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'descriptionProject'"
                :name="'descriptionProject'"
              />
              <label for="descriptionProject">Descrição do Projeto</label>
            </span>
          </div>
          <div
            class="p-field p-col-6 p-md-4 p-grid p-ml-1"
            v-for="type of solutionsTypeList"
            :key="type.id"
          >
            <div class="p-field p-col-12 p-md-12 p-mt-1">
              <span class="p-float-label">
                <label class="p-text-bold" for="solutions">{{
                  type.name
                }}</label>
              </span>

              <div class="p-field p-col-12 p-md-12 p-grid p-ml-1 p-mt-2">
                <div
                  v-for="solution of solutionsList"
                  :key="solution.id"
                  class="p-col-12 p-md-12 p-mt-1"
                  v-show="solution.type_id == type.id"
                >
                  <Checkbox
                    :id="solution.id"
                    name="solution"
                    :value="solution.id"
                    v-model="classification.solutions"
                  />
                  <label :for="solution.id" class="p-ml-2">{{
                    solution.name
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <label for="businessArea">Áreas de Negócio</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-grid p-ml-1">
            <div
              v-for="businessArea of businessAreasList"
              :key="businessArea.id"
              class="p-col-2 p-md-3 p-mt-1"
            >
              <Checkbox
                :id="businessArea.id"
                name="businessArea"
                :value="businessArea.id"
                v-model="classification.business_areas"
              />
              <label :for="businessArea.id" class="p-ml-2">{{
                businessArea.name
              }}</label>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Textarea
                v-model="classification.marketing_action"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'marketingAction'"
                :name="'marketingAction'"
              />
              <label for="marketingAction">Ações de Marketing</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Textarea
                v-model="classification.actions_taken"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'actionsTaken'"
                :name="'actionsTaken'"
              />
              <label for="actionsTaken">Ações Efetuadas</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <FileUpload
              :ref="'filesInput'"
              :name="'files[]'"
              chooseLabel="Adicionar Ficheiros"
              :multiple="true"
              :showUploadButton="false"
              :showCancelButton="false"
              :maxFileSize="2000000000"
            />
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-for="file in filesList"
            :key="file.id"
          >
            <div v-if="file.mimeType == 'application/pdf'" class="pdf-viewer">
              <embed
                :src="`data:${file.mimeType};base64,${file.file}`"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div v-else style="text-align: center">
              <ImagePreview
                width="250"
                :src="`data:${file.mimeType};base64,${file.file}`"
                :alt="file.file_origin_name"
                preview
              />
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Gravar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveClassification"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmCategoriesService from "../../services/crmCategories.service";
import prospectService from "../../services/prospect.service";
import businessService from "../../services/business.service";
import unidecode from "unidecode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "BusinessClassificationForm",
  components: {
    Loading,
  },
  props: ["show", "showToast"],
  watch: {
    async show() {
      if (this.show === true) {
        this.showLoader = false;
        this.$validator.resume();
      } else {
        this.$validator.pause();
        this.$validator.reset();
        this.reset();
      }
    },
  },
  data() {
    return {
      sellerId: null,
      sellerList: [],
      showLoader: false,
      solutionsList: [],
      solutionsTypeList: [],
      businessAreasList: [],
      filesList: [],
      classification: {},
    };
  },
  async created() {
    this.sellerList = await prospectService.getSellers();
    this.solutionsList = await crmCategoriesService.getCategories();
    this.businessAreasList = await businessService.getBusinessAreasList();
    this.solutionsTypeList = await crmCategoriesService.getCategoriesTypes();
  },
  methods: {
    reset() {
      this.sellerId = null;
      this.currentStatus = null;
      this.filesList = [];
      this.classification = {};
      this.showLoader = false;
    },
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    saveClassification() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        var formData = new FormData();
        formData.append("seller_id", parseInt(this.sellerId));
        if (this.classification.case_study != undefined) {
          formData.append(
            "case_study",
            this.classification.case_study == true ? 1 : 0
          );
        }
        formData.append(
          "marketing_action",
          this.classification.marketing_action
            ? this.classification.marketing_action
            : ""
        );

        formData.append(
          "actions_taken",
          this.classification.actionsTaken
            ? this.classification.actionsTaken
            : ""
        );
        formData.append(
          "description_project",
          this.classification.description_project
            ? this.classification.description_project
            : ""
        );
        formData.append(
          "marketing_name",
          this.classification.marketing_name
            ? this.classification.marketing_name
            : ""
        );

        if (this.classification.closed != undefined) {
          formData.append("closed", this.classification.closed == true ? 1 : 0);
        }

        if (this.classification.solutions != undefined) {
          formData.append("solutions", this.classification.solutions.join(";"));
        }
        if (this.classification.business_areas != undefined) {
          formData.append(
            "business_areas",
            this.classification.business_areas.join(";")
          );
        }
        let files = this.$refs["filesInput"];
        if (files.files.length > 0) {
          files.files.forEach((file) => {
            formData.append("files[]", file);
          });
        }
        this.showLoader = true;

        return businessService
          .saveBusinessClassification(formData)
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o atualizar o negócio",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("savedBusinessClassification", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Negócio alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("savedBusinessClassification", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    getSearchLabel(info) {
      return `${unidecode(info.name)}`;
    },
  },
};
</script>
<style>
.p-multiselect-label {
  display: contents !important;
}
.p-multiselect {
  min-height: 33px !important;
}
.p-multiselect-token {
  margin: 2px;
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}
</style>
